import { slugify } from '../../string';
import { parseStepsFromFormulaNotation } from './parsers';
import { getStrategyByMentionChar, getMentionStrategy } from './strategies';
import { MENTION_CHARS_MAP, PARSE_STEP_TO_REGEX, UNDEFINED_STRATEGY, MENTION_ICONS_MAP } from './constants';

// ---------------------------------------- //
// Helpers                                  //
// ---------------------------------------- //

const parseTagToNotation = (mentionElement, strategies) => {
  const strategy = getStrategyByMentionChar(mentionElement.dataset.denotationChar, Object.keys(strategies));
  if (strategy === UNDEFINED_STRATEGY) return;

  const { parsers: { tagToNotation: parser } } = strategy;

  parser(mentionElement);
};

const parseNotationToTag = (html, strategy, list) => {
  if (!Object.keys(MENTION_CHARS_MAP).includes(strategy)) return html;

  // this exist because the formula gem doesnt read dot notation on steps + fields
  // like we use it at beginning: {{step_id:[step_id]}}.{{field_id:[field_id]}}
  // It expects something like {{step_id--[stepid]__result--[field_id]}} in a single mustache.
  // We use dot notation to improve usability and after using it, we parse to a friendly format to formula.
  const parsed = parseStepsFromFormulaNotation(html);
  const { mentionListOptionsFormatter, parsers: { notationToTag: parser } } = getMentionStrategy(strategy);
  const options = mentionListOptionsFormatter(list);

  return parser(parsed, options);
};

const unnestContextSchema = schema => Object.entries(schema).reduce(
  (acc, value) => {
    const [id, schemaValue] = value;

    acc[id] = schemaValue;

    if (schemaValue.type === 'hash') {
      acc = { ...acc, ...unnestContextSchema(schemaValue.properties) };
    }

    return acc;
  },
  {},
);

const parseStepsToFormulaNotation = (html) => {
  let parsed = html;

  const matches = [...html.matchAll(PARSE_STEP_TO_REGEX)];

  matches.forEach((match) => {
    const stepId = match[1];
    const fieldId = match[3];

    if (match[2]) {
      parsed = parsed.replace(match[0], `{{step_id--${stepId}__result--${fieldId}, level:0}}`);
    } else {
      parsed = parsed.replace(match[0], `{{step_id--${stepId}__result--${fieldId}}}`);
    }
  });

  return parsed;
};

// ---------------------------------------- //
// Transformer methods                      //
// ---------------------------------------- //

export const replaceMentionNotationsToTag = (html, mentions, contextSchema = {}) => {
  let parsed = html;

  // if there is a context_schema, the field mentions should be parsed by it

  const unnestedSchema = unnestContextSchema(contextSchema);

  Object.entries(unnestedSchema).forEach(([id, schema]) => {
    const strategy = id.includes('step') ? 'step' : 'field';
    parsed = parseNotationToTag(parsed, strategy, [{ id, ...schema }]);
  });

  // mentions is expected to be something like:
  // { field: { items: [{ id: 1, value: 'Nome' }, { id: 2, value: 'CPF' }], ... } }
  Object.entries(mentions).forEach(([strategy, { items }]) => {
    parsed = parseNotationToTag(parsed, strategy, items);
  });

  return parsed;
};

export const replaceMentionTagsToNotation = (html, parser = text => text, availableStrategies = {}) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  const mentionsHtmlCollection = doc.body.getElementsByClassName('mention');

  // Isso abaixo não é um loop infinito.
  // O mentionsHtmlCollection não é um array, é um HTMLCollection https://developer.mozilla.org/en-US/docs/Web/API/HTMLCollection
  // O HTMLCollection é um objeto "vivo", reativo, que é alterado toda vez que o documento é alterado.
  // Então toda vez que modificamos o HTML (e apagamos o <span class='mention'>), o mentions diminui de tamanho.

  while (mentionsHtmlCollection.length > 0) {
    parseTagToNotation(mentionsHtmlCollection[0], availableStrategies);
  }

  // this exist because the formula gem doesnt read dot notation on steps + fields
  // like we use it at beginning: {{step_id:[step_id]}}.{{field_id:[field_id]}}
  // It expects something like {{step_id--[stepid]__result--[field_id]}} in a single mustache.
  // We use dot notation to improve usability and after using it, we parse to a friendly format to formula.
  const parsedHTML = parseStepsToFormulaNotation(doc.body.innerHTML);

  return parser(parsedHTML);
};

// ---------------------------------------- //
// Render mentions list methods             //
// ---------------------------------------- //

class GetItemsListError extends Error {
  constructor(strategy, originalError) {
    super('Error while getting items list');
    this.originalError = originalError;

    this.errorData = {
      ...originalError.errorData,
      strategy,
    };
  }
}

const renderAvailableMentionOptions = (options, search, renderList) => {
  if (search.length === 0) {
    renderList(options, search);
  } else {
    const filteredOptions = options.filter(option => slugify(option.value).includes(slugify(search)));
    renderList(filteredOptions, search);
  }
};

/**
 * Every time the user types a mention character, this function is called to render the mention options list.
 * @param {string} search - The search string.
 * @param {function} renderList - The function that renders the mention options list.
 * @param {string} mentionChar - The mention character.
 * @param {object} availableMentionsByStrategy - The mentions object.
 *  Example: {
 *    field: {
 *      items: [{ id: 1, value: 'Nome' }, { id: 2, value: 'CPF' }],
 *      findChainItems: async (id) => {...}
 *    },
 *    ...
 *  }
 * @param {string} text - The text before the cursor where the mention is being typed.
 * Example: The user types `@foo`:
 * - the search string is 'foo'
 * - the mentionChar is '@'
 * - the availableMentionsByStrategy is the mentions object
 * - the text is `@`
 */
export const renderMentionsList = async (search, renderList, mentionChar, availableMentionsByStrategy, text) => {
  // To render the mention list, we need to know the strategy of the mentionChar.
  const strategy = getStrategyByMentionChar(mentionChar, Object.keys(availableMentionsByStrategy));

  // If the strategy is UNDEFINED_STRATEGY, it means that the mentionChar is not a mention character.
  // Or it means that the mentionChar is not a mention character that we are handling.
  if (strategy === UNDEFINED_STRATEGY) {
    renderList([], search);
    return;
  }

  // Else, we get the mention items list for the strategy.
  let renderMentionListOptionStrategy = UNDEFINED_STRATEGY;
  let list = [];
  let level;

  try {
    // We call the getItemsList function of the strategy to get the items list.
    const result = await strategy.getItemsList(text, availableMentionsByStrategy);
    // We get the renderMentionListOptionStrategy, the options list and the level from the result.
    renderMentionListOptionStrategy = result.renderMentionListOptionStrategy;

    if (renderMentionListOptionStrategy === UNDEFINED_STRATEGY) {
      renderList([], search);
      return;
    }

    list = result.list;
    // The level means the depth of the chain mention.
    // For example, if the user types `@foo.bar`, the level of foo is 0 and the level of bar is 1.
    level = result.level;
  } catch (error) {
    renderList([], search);
    throw new GetItemsListError(strategy, error);
  }

  const { mentionListOptionsFormatter } = getMentionStrategy(renderMentionListOptionStrategy);
  const formattedMentionListOptions = mentionListOptionsFormatter(list, level);

  // Now, search will filter the possible options and render the list.
  renderAvailableMentionOptions(formattedMentionListOptions, search, renderList);
};

export { MENTION_CHARS_MAP, MENTION_ICONS_MAP };
