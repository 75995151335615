import { UNDEFINED_STRATEGY, MENTION_CHARS_MAP, PARENT_MENTION_REGEX } from './constants';

/**
 * Gets the index of the filter start in the expression.
 * @param {string} expression The expression before the mention char.
 * @param {string} mentionChar The mention char.
 * @returns {number}
 */
export const getFilterStartIndex = (expression, mentionChar) => {
  // Checks matching brackets to find filter start/end in input string
  const bracketStack = [];

  const reversedIndex = expression.split('').reverse().findIndex((item) => {
    if (item === '[') {
      if (!bracketStack.length) return true;

      if (bracketStack[bracketStack.length - 1] === ']') {
        bracketStack.pop();
      }

      if (!bracketStack.length && mentionChar === MENTION_CHARS_MAP.chain) return true; // Look for nearest closed filter
    }

    if (item === ']') {
      bracketStack.push(item);
    }

    return false;
  });

  return reversedIndex === -1 ? -1 : expression.length - reversedIndex - 1;
};

const getFilterParentField = (expression, mentionChar) => {
  const result = { parentFieldId: null };

  if (expression) {
    const filterStartIndex = getFilterStartIndex(expression, mentionChar);
    if (filterStartIndex === -1) return result;

    const expressionUntilLastOpenFilter = expression.slice(0, filterStartIndex);
    const regex = /\{\{(field_id:([A-Za-z0-9-]*)(, level:0)?)}}(?!(.))/g;
    const match = expressionUntilLastOpenFilter.matchAll(regex).next();

    if (match.value) {
      const [,, parentFieldId,, level] = match.value;
      result.parentFieldId = parentFieldId;

      if (level) {
        result.level = parseInt(level, 10);
      }
    }
  }

  return result;
};

const getParentMention = (text) => {
  const result = { strategy: UNDEFINED_STRATEGY };
  // ---------------------------------------- //
  // Gets parent mention strategy from a dot  //
  // ---------------------------------------- //
  const match = text.matchAll(PARENT_MENTION_REGEX).next();

  if (match.value) {
    // regex matches
    // [2] is the strategy (field or step) of the mustache before the last dot
    // [3] is the referred strategy's id
    // [4] is the level, if present
    // [5] is the filter, if present
    const [,, strategy, id,, filter] = match.value;
    result.strategy = strategy;
    result.id = id;

    if (filter && result.strategy !== 'step') {
      const { parentFieldId } = getFilterParentField(text, MENTION_CHARS_MAP.chain);
      result.id = parentFieldId;
    }
  }

  return result;
};

// ---------------------------------------- //
// Mention items list                       //
// ---------------------------------------- //

/**
 * Every time a field mention char is typed, this function is called to get the list of items to be rendered.
 * @param {string} text - The text where the mention is being typed. It must not contain the content after the mention char.
 * Example:
 *   Full formula expression with mention char: '{{field_id:123, level:0}}[@].{{field_id:456}}'
 *   text: '{{field_id:123, level:0}}[@
 * @param {Object} availableMentionsByStrategy - The mentions Object.
 * Example: {
 *   field: {
 *     items: [{ id: 1, value: 'Nome' }, { id: 2, value: 'CPF' }],
 *     findChainItems: async (id) => {...}
 *   },
 *   ...
 * }
 * @returns {Object}
 * Example: {
 *   renderMentionListOptionStrategy: 'field',
 *   list: [{ id: 1, value: 'Nome' }, { id: 2, value: 'CPF' }],
 *   level: 0,
 * }
 */
export const getFieldItemsList = async (_text, availableMentionsByStrategy) => ({ renderMentionListOptionStrategy: 'field', list: availableMentionsByStrategy.field?.items || [], level: 0 });

/**
* Every time a field mention char is typed, this function is called to get the list of items to be rendered.
* @param {string} text - The text where the mention is being typed. It must not contain the content after the mention char.
* Example:
*   Full formula expression with mention char: '{{field_id:123, level:0}}[#].{{field_id:456}}'
*   text: '{{field_id:123, level:0}}[#
* @param {Object} availableMentionsByStrategy - The mentions Object.
* Example: {
*   field: {
*     items: [{ id: 1, value: 'Nome' }, { id: 2, value: 'CPF' }],
*     findChainItems: async (id) => {...}
*   },
*   ...
* }
* @returns {Object}
* Example: {
*   renderMentionListOptionStrategy: 'field',
*   list: [{ id: 1, value: 'Nome' }, { id: 2, value: 'CPF' }],
*   level: 0,
* }
*/
export const getChildFieldItemsList = async (text, availableMentionsByStrategy) => {
  const result = { renderMentionListOptionStrategy: UNDEFINED_STRATEGY };
  // if the field mention char is called inside filter, we need to get the parent field id
  const { parentFieldId } = getFilterParentField(text, MENTION_CHARS_MAP.field);

  // if the field mention char is called inside a chain, we need to get the parent field id
  if (parentFieldId) {
    result.renderMentionListOptionStrategy = 'field';
    const getListItems = availableMentionsByStrategy.field?.findChainItems || (_ => []);

    const list = await getListItems(parentFieldId);

    result.list = list;
  }

  // if the field mention char is called outside a chain, we just return the list
  return result;
};

/**
 * Every time a chain mention char is typed, this function is called to get the list of items to be rendered.
 * @param {string} text - The text where the mention is being typed. It must not contain the content after the mention char.
 * @param {Object} availableMentionsByStrategy - The mentions Object.
 * @returns {Object}
 * Example: {
 *   renderMentionListOptionStrategy: 'field',
 *   list: [{ id: 1, value: 'Nome' }, { id: 2, value: 'CPF' }],
 *   level: 1,
 * }
 */
export const getChainItemsList = async (text, availableMentionsByStrategy) => {
  const { strategy: renderMentionListOptionStrategy, id: parentMentionId } = getParentMention(text);
  const result = { renderMentionListOptionStrategy };

  if (renderMentionListOptionStrategy === UNDEFINED_STRATEGY) return result;

  const getListItems = availableMentionsByStrategy[renderMentionListOptionStrategy]?.findChainItems || (_ => []);

  result.list = await getListItems(parentMentionId);

  return result;
};

export const getStepItemsList = (_, availableMentionsByStrategy) => ({
  renderMentionListOptionStrategy: 'step',
  list: availableMentionsByStrategy.step?.items || [],
  level: 0,
});

export const getFormulaFunctionItemsList = (_, availableMentionsByStrategy) => ({
  renderMentionListOptionStrategy: 'formula_function',
  list: availableMentionsByStrategy.formula_function?.items || [],
});

export const getCustomItemsList = (_, availableMentionsByStrategy) => ({
  renderMentionListOptionStrategy: 'custom',
  list: availableMentionsByStrategy.custom?.items || [],
});

export { UNDEFINED_STRATEGY };
