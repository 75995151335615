import { floatifyNumber, formatWithThousandSeparator } from '~/assets/javascript/utils/number';
import { COLORS } from '~/assets/javascript/constants/colors';

export const DECIMAL_SEPARATOR_MAPPING = Object.freeze({
  pt: ',',
  'pt-BR': ',',
  en: '.',
  'en-US': '.',
});

export const CHECKLIST_ESCAPE_CHARS_MAPPING = Object.freeze({
  '"': '&quot;',
});

export const EXHIBITION_MODE_OPTIONS = Object.freeze({
  Minicard: 'minicard',
  Label: 'label',
  Text: 'text',
});

export const FIELD_TYPE_KEY_DISPLAY_MAPPING = Object.freeze({
  User: 'username',
  CoverImage: 'filename',
  Attachment: 'filename',
  Link: 'foreign_record_display_name',
  Lookup: 'foreign_record_display_name',
  Checklist: 'items',
  Select: 'select_option_display_name',
  MultipleSelect: 'select_option_display_name',
  Date: 'display_name',
  DateTime: 'display_name',
  Number: 'value',
  Formula: 'value',
});

export const FIELD_TYPE_KEY_SORT_MAPPING = Object.freeze({
  ...FIELD_TYPE_KEY_DISPLAY_MAPPING,
  Date: 'value',
});

export const FIELD_TYPE_FUNCTION_DISPLAY_MAPPING = Object.freeze({
  Number: (field, value, _mapping) => {
    let formattedValue = floatifyNumber(value, field.options.number_options.precision);
    const decimalSeparator = DECIMAL_SEPARATOR_MAPPING[field.i18nOptions.locale];
    const thousandSeparator = decimalSeparator === ',' ? '.' : ',';

    formattedValue = formattedValue.toFixed(field.options.number_options.precision);

    if (field.options.number_options.precision > 0) {
      formattedValue = formattedValue.replace('.', decimalSeparator).split(decimalSeparator);
      formattedValue = [formatWithThousandSeparator(formattedValue[0], thousandSeparator), formattedValue[1]].join(decimalSeparator);
    } else {
      formattedValue = formatWithThousandSeparator(formattedValue, thousandSeparator);
    }

    return [field.options.number_options.prefix, formattedValue, field.options.number_options.suffix].join(' ').trim();
  },
  Lookup: (field, value, mapping) => {
    if (['Date', 'DateTime'].includes(field.options.lookup_field_type)) return value[mapping[field.options.lookup_field_type]];

    return value;
  },
  Checklist: (_field, value) => {
    let { text } = value;

    Object.entries(CHECKLIST_ESCAPE_CHARS_MAPPING).forEach(([char, escapedChar]) => {
      text = text.replaceAll(char, escapedChar);
    });

    return `[${value.checked ? 'x' : ' '}] ${text}`;
  },
});

export const DATE_FORMATS = Object.freeze([
  'DD/MMMM',
  'DD/MM/YYYY',
  'MMMM/YYYY',
  'MM/YYYY',
  'YYYY',
  // epoch ms format
  'epoch_ms',
]);

export const DATE_TIME_FORMATS = Object.freeze([
  ...DATE_FORMATS.filter(format => format !== 'epoch_ms'), // initially remove epoch_ms
  'DD/MM/YYYY HH:mm',
  'DD/MM HH:mm',
  'HH:mm',
  'epoch_ms', // ensure epoch_ms is always the last item
]);

// The order of DATE_FORMATS constant doesn't work :/
// The parse of '07/2016' using the DATE_FORMATS returns `Invalid date'
// So the array order must be the following to work
export const MOMENT_DATE_SORTED_FORMATS = Object.freeze([
  'YYYY',
  'MM/YYYY',
  'DD/MMMM',
  'MMMM/YYYY',
  'DD/MM/YYYY',
  'DD/MM/YYYY HH:mm',
  'DD/MM HH:mm',
  'HH:mm',
  'epoch_ms',
]);

/**
 * Converter Map for the date formats received from the API.
 *
 * Keys are the FORMATS expected to render on the UI, received from the API
 *
 * Values are the format of the actual VALUES also received from the API, to
 * help moment.js parse the date correctly on all Browsers safely.
 * (I'm looking at you, Safari)
 */
export const DATE_TIME_RECEIVED_FORMATS_MAPPING = Object.freeze({
  '%Y': 'YYYY',
  '%d/%B': 'MM-DD',
  '%B/%Y': 'YYYY-MM',
  '%m/%Y': 'YYYY-MM',
  '%d/%m/%Y': 'YYYY-MM-DD',
  '%d/%B %H:%M': 'MM-DD[T]HH:mm',
  '%d/%m/%Y %H:%M': 'YYYY-MM-DD[T]HH:mm',
  '%H:%M': 'HH:mm',
  '%Q': datetime => datetime?.valueOf()?.toString(),
});

export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const ISO_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
export const DEFAULT_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';

export const DATE_FORMATS_SUBMIT_MAPPING = Object.freeze(DATE_TIME_FORMATS.reduce((accumulator, current) => {
  // These formats dont necessarily match the substitution pattern below
  const mappedFormats = {
    epoch_ms: '%Q',
  };

  if (mappedFormats[current]) {
    accumulator[current] = mappedFormats[current];
  } else {
    accumulator[current] = current
      .replaceAll('DD', '%d')
      .replaceAll('MMMM', '%B')
      .replaceAll('MM', '%m')
      .replaceAll('YYYY', '%Y')
      .replaceAll('HH', '%H')
      .replaceAll('mm', '%M');
  }

  return accumulator;
}, {}));

export const FORMULA_ALLOWED_FIELD_TYPES = Object.freeze([
  'Attachment',
  'Checklist',
  'CoverImage',
  'Date',
  'DateTime',
  'Link',
  'Lookup',
  'Markdown',
  'MultipleSelect',
  'Number',
  'Select',
  'String',
  'User',
]);

const FUNCTIONS = Object.freeze([
  'ABS',
  'AND',
  'ARRAYCOMPACT',
  'ARRAYFLATTEN',
  'ARRAYJOIN',
  'ARRAYLEFT',
  'ARRAYRIGHT',
  'ARRAYUNIQUE',
  'AVERAGE',
  'CEILING',
  'CONTAINS',
  'CONTAINSALL',
  'CONTAINSANY',
  'COUNT',
  'COUNTA',
  'COUNTALL',
  'COUNTIF',
  'DATE',
  'DATE_DIFF',
  'DATETIME',
  'DATETIME_FORMAT',
  'FIRST',
  'FLOOR',
  'IF',
  'IFERROR',
  'INDEX',
  'INT',
  'INTERVAL',
  'ISBLANK',
  'ISWEEKEND',
  'LAST',
  'LEFT',
  'LEN',
  'LOG',
  'MAX',
  'MEDIAN',
  'MIN',
  'MOD',
  'NOT',
  'OR',
  'POWER',
  'RAND',
  'RANDBETWEEN',
  'REPLACE',
  'RIGHT',
  'ROUND',
  'ROUNDDOWN',
  'ROUNDUP',
  'SAMPLE',
  'SEQUENCE',
  'SPLIT',
  'SQRT',
  'STDEV',
  'SUM',
  'SUMIF',
  'TODAY',
  'WEEKDAY',
  'XOR',
  'ZODIAC',
]);

export const FORMULA_FUNCTIONS = Object.freeze(FUNCTIONS.map(
  (formula, index) => ({ id: index + 1, value: formula }),
));

// Sort formula functions from longest to shortest name,
// this way, mentions are replaced by searching for the longest functions first
// (e.g. RAND is mentioned before AND)
export const FORMULA_FUNCTIONS_SORTED_BY_LENGTH = FORMULA_FUNCTIONS
  .slice() // Shallow copy
  .sort((a, b) => b.value.length - a.value.length);

export const MASK_INCOMPATIBLE_RULES = Object.freeze(['max_characters']);

export const DEFAULT_MASK_OPTIONS = Object.freeze({
  noMask: {
    mask: null,
    example: null,
  },
  CEP: {
    mask: '#####-###',
    example: '00000-000',
  },
  CNPJ: {
    mask: '##.###.###/####-##',
    example: '12.345.678/1234-12',
  },
  CPF: {
    mask: '###.###.###-##',
    example: '123.456.789-00',
  },
  PhoneBR: {
    mask: '(##) #####-####',
    example: '(00) 99999-9999',
  },
  PhoneWithCountryCode: {
    mask: '+### (##) #####-####',
    example: '+123 (00) 99999-9999',
  },
  TituloDeEleitorBR: {
    mask: '#### #### ####',
    example: '1234 5678 9012',
  },
  // BankAgencyBR: {
  //   mask: ['###', '####', '####-X'],
  //   example: '1234-5',
  // },
  // BankAccountBR: {
  //   mask: ['####-#', '#####-X', '######-#', '########-#', '#######-##'],
  //   example: '12345678-9',
  // },
});

export const SELECT_OPTIONS_TEMPLATE = Object.freeze({
  NPS: {
    icon: 'fa-poll-people fa-regular',
    options: [{
      value: '1',
      internal_value: -1,
      color: COLORS.blue.accent,
    }, {
      value: '2',
      internal_value: -1,
      color: COLORS.blue.accent,
    }, {
      value: '3',
      internal_value: -1,
      color: COLORS.blue.accent,
    }, {
      value: '4',
      internal_value: -1,
      color: COLORS.blue.accent,
    }, {
      value: '5',
      internal_value: -1,
      color: COLORS.blue.accent,
    }, {
      value: '6',
      internal_value: -1,
      color: COLORS.blue.accent,
    }, {
      value: '7',
      internal_value: 0,
      color: COLORS.blue.accent,
    }, {
      value: '8',
      internal_value: 0,
      color: COLORS.blue.accent,
    }, {
      value: '9',
      internal_value: 1,
      color: COLORS.blue.accent,
    }, {
      value: '10',
      internal_value: 1,
      color: COLORS.blue.accent,
    }],
  },
  CSAT: {
    icon: 'fa-smile fa-regular',
    options: [{
      value: '😟',
      internal_value: -1,
      color: COLORS.red.base,
    }, {
      value: '😐',
      internal_value: 0,
      color: COLORS.yellow.base,
    }, {
      value: '😄',
      internal_value: 1,
      color: COLORS.green.base,
    }],
  },
  Likert: {
    icon: 'fa-scale-balanced',
    options: [{
      value: 'Strongly Disagree',
      internal_value: -2,
      color: COLORS.red.dark,
    }, {
      value: 'Disagree',
      internal_value: -1,
      color: COLORS.red.base,
    }, {
      value: 'Neutral',
      internal_value: 0,
      color: COLORS.yellow.base,
    }, {
      value: 'Agree',
      internal_value: 1,
      color: COLORS.green.base,
    }, {
      value: 'Strongly Agree',
      internal_value: 2,
      color: COLORS.green.dark,
    }],
  },
  Stars: {
    icon: 'fa-regular fa-star',
    options: [{
      value: '⭐',
      internal_value: 1,
      color: COLORS.blue.accent,
    }, {
      value: '⭐⭐',
      internal_value: 2,
      color: COLORS.blue.accent,
    }, {
      value: '⭐⭐⭐',
      internal_value: 3,
      color: COLORS.blue.accent,
    }, {
      value: '⭐⭐⭐⭐',
      internal_value: 4,
      color: COLORS.blue.accent,
    }, {
      value: '⭐⭐⭐⭐⭐',
      internal_value: 5,
      color: COLORS.blue.accent,
    }],
  },
  YesNoMaybe: {
    icon: 'fa-regular fa-thumbs-up',
    options: [{
      value: 'Yes',
      internal_value: 1,
      color: COLORS.green.base,
    }, {
      value: 'No',
      internal_value: -1,
      color: COLORS.red.base,
    }, {
      value: 'Maybe',
      internal_value: 0,
      color: COLORS.yellow.base,
    }],
  },
});

export const FIELD_CALCULATED_TYPES_ENABLED = Object.freeze([
  'Number',
  'String',
  'Markdown',
  'Select',
  'MultipleSelect',
  'Date',
  'DateTime',
  'Link',
]);

export const FORMULA_OPTIONS_KEYS = Object.freeze(['expression', 'context_schema', 'valid_expression']);

export const FIELD_OPTIONS_KEYS = Object.freeze({
  Formula: FORMULA_OPTIONS_KEYS,
});

export const FIELD_VISIBILITY_OPTIONS = Object.freeze({
  PUBLIC: 'public',
  CONFIDENTIAL: 'confidential',
});
