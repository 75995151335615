import marked from '../../modules/marked';
import { sanitizeHtml } from '../html';
import { parseImageResizeMustache } from './image-resize';
import { addProtocolToMarkdownLinks } from './markdown';
import { parseEmbeddedVideo } from './video';

const transformTextIntoTemporaryHTMLElement = (text) => {
  const wrapper = document.createElement('div');
  wrapper.classList.add('temp-wrapper');
  wrapper.innerHTML = text;
  return wrapper;
};

const extractElementInnerHTML = html => html.innerHTML;

const countParentsWithTag = (element, tagName) => {
  let count = 0;
  let currentElement = element.parentNode;

  while (currentElement !== null) {
    if (currentElement.tagName.toUpperCase() === tagName.toUpperCase()) {
      count++;
    }

    currentElement = currentElement.parentNode;
  }

  return count;
};

const unwrapNestedLists = (rootUl) => {
  const rootListItems = [...rootUl.children].filter(child => child.tagName === 'LI');

  rootListItems.forEach((li) => {
    const nestedListItems = [...li.querySelectorAll('li')].reverse();

    nestedListItems.forEach((nestedLi) => {
      rootUl.insertBefore(nestedLi, li.nextSibling);
    });
  });

  rootUl.querySelectorAll('ul, ol').forEach((ul) => {
    if (ul.children.length === 0) ul.remove();
  });
};

const addQuillIndentClasses = (element) => {
  const listItems = element.querySelectorAll('li');

  Array.from(listItems).forEach((listItem) => {
    const ulCount = countParentsWithTag(listItem, 'ul');
    const olCount = countParentsWithTag(listItem, 'ol');
    const depth = ulCount + olCount - 1;

    if (depth > 0) {
      listItem.classList.add(`ql-indent-${depth}`);
    }
  });
};

const formatBulletPoints = (text) => {
  // quill cannot render nested bullet points -> https://github.com/quilljs/quill/issues/979
  // so we need to manually parse the correct html from marked to something quill can understand as nested bullet points
  if (!window) return text;
  const element = transformTextIntoTemporaryHTMLElement(text);
  const topLevelLists = element.querySelectorAll('.temp-wrapper > ul, .temp-wrapper > ol');

  Array.from(topLevelLists).forEach((list) => {
    addQuillIndentClasses(list);
    unwrapNestedLists(list);
  });

  return extractElementInnerHTML(element);
};

export const formatHTMLToQuillValidHTML = (html) => {
  let formatedValidQuillHTML = html
    // quill uses <s> instead of <del> for strikethrough
    .replaceAll('<del>', '<s>')
    .replaceAll('</del>', '</s>')
    // don't know why, but if there are line breaks in the middle of a list, quill will not render it. Also <p> tags inside of it.
    .replaceAll(/^<li><p>(.*)<\/p>$/gm, '<li>$1')
    .replaceAll('\n<li>', '<li>')
    .replaceAll('</li>\n', '</li>')
    .replaceAll('\n<li>', '<li>')
    .replaceAll('&quot;', '"')
    // quill uses <blockquote> instead of <blockquote><p> for blockquotes
    .replaceAll(/<blockquote>\s*?<p>/g, '<blockquote>')
    .replaceAll(/<\/p>\s*?<\/blockquote>/g, '</blockquote>')
    .replaceAll('&amp;lt;', '&lt;')
    .replaceAll('&amp;gt;', '&gt;')
    .replaceAll('<pre><code>', '<pre>')
    .replaceAll('</code></pre>', '</pre>')
    .replaceAll('<pre>', '<pre class="ql-syntax">')
    .replaceAll(/>\n+/g, '>')
    .replaceAll(/\n+</g, '<')
    .replaceAll(/>\s+</g, '><')
    .replaceAll('<li></li>', '<li><br></li>')
    // quill is not able to render tabs, so we replace them with 4 spaces
    .replaceAll(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');

  formatedValidQuillHTML = formatBulletPoints(formatedValidQuillHTML);

  return formatedValidQuillHTML;
};

const clearNonBreakingSpaces = text => text?.replaceAll('&nbsp;', ' ') || '';

export const formatMarkdownToQuillValidHTML = (markdown, sanitizer, transformers = []) => {
  let result = clearNonBreakingSpaces(markdown);
  result = parseEmbeddedVideo(result);
  result = addProtocolToMarkdownLinks(result);
  result = marked.parse(result);
  result = formatHTMLToQuillValidHTML(result);
  result = parseImageResizeMustache(result);

  transformers.forEach((transformer) => {
    result = transformer(result);
  });

  return sanitizeHtml(result, sanitizer);
};

export const formatFormulaToQuillValidHTML = (formula, sanitizer, transformers = []) => {
  let result = clearNonBreakingSpaces(formula);
  // Sanitize only if formula, since '>' denotes blockquote in markdown
  result = result.replaceAll('<', '&amp;lt;').replaceAll('>', '&amp;gt;');
  result = result.split('\n').map(match => `<p>${match || '<br>'}</p>`).join('');
  result = formatHTMLToQuillValidHTML(result);

  transformers.forEach((transformer) => {
    result = transformer(result);
  });

  return sanitizeHtml(result, sanitizer);
};
