import { isObject, isArray, cloneDeep, compact } from 'lodash';
import moment from 'moment-timezone';
import { arrayToString, getFieldDisplayNameAsString, integerifyNumberFromString, stringToArray } from '~/assets/javascript/utils';
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT, ISO_DATE_FORMAT, ISO_DATE_TIME_FORMAT } from '~/assets/javascript/constants';
import { stringToChecklist } from '~/assets/javascript/utils/table/Cell/Checklist';

export const STEPS = {
  initial: 'initial',
  manipulation: 'manipulation',
};

export const SUPPORTED_FIELD_TYPES = [
  'Checklist',
  'Date',
  'DateTime',
  'Link',
  'Markdown',
  'MultipleSelect',
  'Number',
  'Select',
  'String',
  'User',
];

const BRAZILIAN_DATE_FORMAT_REGEX = /(\d{2})\/(\d{2})\/(\d{4}|\d{2})/;

function formatNumber({ record, field, fieldId, separator }) {
  const value = record[fieldId];

  if (isObject(value) && value.value) {
    record[fieldId] = value;
  } else if (value) {
    const value = integerifyNumberFromString(record[fieldId], separator, field.options.number_options.precision);

    if (value) {
      record[fieldId] = { value };
    } else {
      record[fieldId] = null;
    }
  } else {
    record[fieldId] = null;
  }
}

function undoComplexFormats({ record, fieldId }) {
  const value = record[fieldId];

  if (isObject(value)) {
    record[fieldId] = value.username || value.value;
  } else if (isArray(value)) {
    record[fieldId] = value.map(value => value.foreign_record_display_name).join(',');
  }
}

function formatTextToSelect({ record, field, fieldId }) {
  const value = record[fieldId];
  const values = value ? stringToArray(value) : [];

  const options = compact(values.map(
    value => field.options.select_options.find(option => option.value === value),
  ));

  record[fieldId] = options.map(({ id }) => id);
}

function formatSelectOptionToText({ record, field, fieldId }) {
  const optionId = record[fieldId][0];

  record[fieldId] = field.options.select_options.find(({ id }) => id === optionId)?.value;
}

export function formatDate({ record, fieldId }) {
  let date = record[fieldId];

  if (date) {
    if (date.match(BRAZILIAN_DATE_FORMAT_REGEX)) {
      // convert to ISO format
      date = moment(date, DEFAULT_DATE_FORMAT).format(ISO_DATE_FORMAT);
    }

    record[fieldId] = {
      display_name: moment(date).format(DEFAULT_DATE_FORMAT),
      value: moment(date).format(ISO_DATE_FORMAT),
    };
  }
}

export function formatDateTime({ record, fieldId }) {
  const date = record[fieldId];

  if (date) {
    record[fieldId] = {
      display_name: moment(date).format(DEFAULT_DATE_TIME_FORMAT),
      value: moment(date).format(ISO_DATE_TIME_FORMAT),
    };
  }
}

function formatDateToFinalValue({ record, fieldId }) {
  if (record[fieldId]) {
    record[fieldId] = record[fieldId].value;
  }
}

function formatUser({ record, field, fieldId }) {
  const value = record[fieldId];
  const user = field.users.find(({ username }) => username === value?.username || username === value) || {};

  if (isObject(value)) {
    record[fieldId] = value;
  } else {
    record[fieldId] = {
      username: value,
      can_log_in: true,
      role: 'member',
      ...user,
    };
  }

  if (record.state === 'success') return;

  if (user.people_record_id) {
    record[fieldId].already_in_use = true;
    record.state = 'disabled';
  } else {
    delete record[fieldId].already_in_use;
  }
}

export function formatLinks({ record, fieldId, field }) {
  const { records } = field.foreignSheet.view;
  const { foreign_field_id: foreignFieldId } = field.options;
  const rawData = record[fieldId];

  if (isArray(rawData)) return;

  if (rawData) {
    const values = rawData.split(',').map(value => value.trim().toLowerCase());

    record[fieldId] = records
      .map(record => ({
        foreign_record_id: record.id,
        foreign_record_display_name: getFieldDisplayNameAsString(record.data[foreignFieldId], { ...field, i18nOptions: {} }),
      }))
      .filter(({ foreign_record_display_name: displayName }) => values
        .includes(displayName?.trim()?.toLowerCase()));
  } else {
    record[fieldId] = [];
  }
}

function formatTextToChecklist({ record, fieldId }) {
  const value = record[fieldId];

  record[fieldId] = stringToChecklist(value);
}

function formatChecklistToText({ record, fieldId }) {
  const value = record[fieldId];

  record[fieldId] = arrayToString(value.items.map(({ checked, text }) => `[${checked ? 'x' : ' '}] ${text}`));
}

function formatLinkToImport({ record, fieldId }) {
  record[fieldId] = record[fieldId].map(link => link.foreign_record_id);
}

export const OMITTED_COLUMNS = [
  'id',
  'state',
  'customAttributes',
];

const FORMAT = {
  Select: formatTextToSelect,
  MultipleSelect: formatTextToSelect,
  String: undoComplexFormats,
  Number: formatNumber,
  Date: formatDate,
  DateTime: formatDateTime,
  User: formatUser,
  Link: formatLinks,
  Checklist: formatTextToChecklist,
};

const IMPORT_FORMAT = {
  Date: formatDateToFinalValue,
  DateTime: formatDateToFinalValue,
  Link: formatLinkToImport,
};

export const UPDATE_FORMAT = {
  Date: formatDateToFinalValue,
  DateTime: formatDateToFinalValue,
  Select: formatSelectOptionToText,
  MultipleSelect: formatSelectOptionToText,
  Checklist: formatChecklistToText,
};

const transformImportedRecordsInForeignRecords = (records, sheet) => records.map(record => ({
  id: record.id,
  data: Object
    .entries(record)
    .reduce((acc, [key, value]) => {
      const field = sheet.view.fields.find(({ name }) => name === key);

      if (field) {
        acc[field.id] = value;
      }

      return acc;
    }, {}),
}));

export function getSelfLinkFields(fields, sheets) {
  const currentSheet = sheets.find(sheet => sheet.view.fields.some(({ id }) => id === fields?.[0]?.id));

  return fields.filter(field => field.type === 'Link' && field.options.foreign_sheet_id === currentSheet.id);
}

export function formatRecords(records, matchedColumns, users, sheets, currentRecords, separator) {
  const selfLinkFields = getSelfLinkFields(Object.values(matchedColumns), sheets);

  const fields = Object
    .entries(matchedColumns)
    .filter(([_, field]) => Object.keys(FORMAT).includes(field.type))
    .map(([fieldId, field]) => {
      const newField = { ...field };

      if (field.type === 'Link') {
        const foreignSheet = cloneDeep(sheets.find(sheet => sheet.id === field.options.foreign_sheet_id));

        if (selfLinkFields.some(({ id }) => id === field.id)) {
          foreignSheet.view.records = [...foreignSheet.view.records, ...transformImportedRecordsInForeignRecords(currentRecords, foreignSheet)];
        }

        newField.foreignSheet = foreignSheet;
      } else if (field.type === 'User') {
        newField.users = users;
      }

      return [fieldId, newField];
    });

  return records.map((record) => {
    fields.forEach(([fieldId, field]) => {
      if (FORMAT[field.type]) {
        FORMAT[field.type]({ record, field, fieldId, separator });
      }
    });

    if (record.state === 'success') {
      record.customAttributes = { class: 'green lighten-5', disabled: true };
    } else if (record.state === 'success-first-step') {
      record.customAttributes = { class: 'light-green lighten-5', disabled: true };
    } else if (record.state === 'error') {
      record.customAttributes = { class: 'red lighten-5' };
    } else if (record.state === 'disabled') {
      record.customAttributes = { class: 'grey lighten-5', disabled: true };
    }

    return record;
  });
}

export function formatRecordToImport(record, matchedColumns, removedColumns) {
  return Object
    .entries(record)
    .reduce((data, [fieldId, value]) => {
      if ([...removedColumns, ...OMITTED_COLUMNS].includes(fieldId)) return data;
      const field = matchedColumns[fieldId];

      if (value) {
        if (field.type === 'User') {
          if (value.username) {
            data.user = value;

            if (!value.id) {
              data.fields[field.id] = value.username?.trim();
            }
          }
        } else {
          data.fields[field.id] = value;

          if (IMPORT_FORMAT[field.type]) {
            IMPORT_FORMAT[field.type]({ record: data.fields, field, fieldId: field.id });
          }
        }
      }

      return data;
    }, { fields: {}, user: null });
}

const validRecordData = (record, fields) => Object.entries(fields).every(([fieldKey, field]) => {
  if (!['Date', 'DateTime'].includes(field.type)) return true;
  if (record[fieldKey].value === 'Invalid date' || record[fieldKey].display_name === 'Invalid date') return false;
  return true;
});

export const validateAndFilterRecordForImport = (state, selected, commit) => (record) => {
  if (record.state === 'success') return false;
  if (record.state === 'disabled') return false;

  if (validRecordData(record, state.matchedColumns)) {
    if (selected.size > 0) return selected.has(record.id);
    return true;
  }

  if ((selected.size > 0 && selected.has(record.id)) || selected.size === 0) {
    commit('setRecordState', { recordIndex: parseInt(record.id, 10), recordState: 'error' });
  }

  return false;
};
