
const STEP_RESULT_KEY_REGEX = /[\w-]/;
const STEP_KEY_REGEX = /[A-Za-z0-9-]/;

export const PARSE_STEP_TO_REGEX = new RegExp(`{{(?:step_id:(${STEP_KEY_REGEX.source}*))(, level:0)?}}\\s?\\.\\s?{{(?:field_id:(${STEP_RESULT_KEY_REGEX.source}*))(, level:0)?}}`, 'g');
// Matches patterns like "{{step_id:<step_key>}} . {{field_id:<step_result_key>}}", optionally followed by ", level:0".
// Example: "{{step_id:123}} . {{field_id:abc}}" or "{{step_id:123, level:0}} . {{field_id:abc}}"

export const PARSE_STEP_FROM_REGEX = new RegExp(`{{step_id--(${STEP_KEY_REGEX.source}*)__result--(${STEP_RESULT_KEY_REGEX.source}*)(, level:0)?}}`, 'g');
// Matches patterns like "{{step_id--<step_key>__result--<step_result_key>}}", optionally followed by ", level:0".
// Example: "{{step_id--123__result--abc}}" or "{{step_id--123__result--abc, level:0}}"

export const PARENT_MENTION_REGEX = /(?:{{(?:(?:field|step)(?:_id:|_id--)(?:[A-Za-z0-9-_]*))(, level:0)?}}\s?\.\s?)*{{(field|step)(?:_id:|_id--)([A-Za-z0-9-_]*)(, level:0)?}}\s?(\[.*\])?\.(?![{{.])/g;
// Matches nested patterns like "{{field_id:<id>}} . {{step_id:<id>}}", optionally followed by ", level:0" and an optional array filter.
// Example: "{{field_id:123}} . {{step_id:abc}}[{{field_id:def}}=10]" or "{{field_id:123, level:0}} . {{step_id:abc}}[{{field_id:def}}]"

export const UNDEFINED_STRATEGY = 'undefined_strategy';

export const MENTION_CHARS_MAP = {
  field: '@',
  child_field: '#',
  chain: '.',
  step: '&',
  formula_function: '$',
  custom: '@',
};

export const MENTION_ICONS_MAP = {
  field: 'at',
  child_field: 'hashtag',
  chain: 'period',
  step: 'ampersand',
  formula_function: 'dollar-sign',
  custom: 'at',
};
