<i18n lang="yaml">
  pt:
    apply: 'Candidate-se'
  en:
    apply: 'Apply'
</i18n>

<template>
  <v-app id="inspire" class="public-layout">
    <PublicTopbar v-if="!routesWithoutTopbar" />

    <v-main class="public-layout__wrapper">
      <nuxt />
      <deck-button
        v-if="showApplyButton"
        class="public-layout__apply-button"
        size="large"
        :text="$t('apply')"
        @click="onApplyButtonClick"
      />
    </v-main>

    <Snackbar />
    <TheDialog />
  </v-app>
</template>

<script>
import RedirectToView from '~/assets/javascript/modules/member/actions/redirect-to-view';
import Snackbar from '~/components/Snackbar';
import TheDialog from '~/components/dialogs/the-dialog';
import PublicTopbar from '~/components/topbars/public-topbar';
import DeckButton from '~/deck/button';
import { workspace } from '~/mixins/workspace';
import { mapMutations, mapState } from 'vuex';
import ROUTES from '~/assets/javascript/constants/routes';

const routesWithoutTopbar = [
  't-tenantSlug-login',
  't-tenantSlug-sign-up',
  't-tenantSlug-reset-password',
];

export default {
  name: 'Public',
  components: {
    Snackbar,
    TheDialog,
    DeckButton,
    PublicTopbar,
  },
  mixins: [workspace({ isPublic: true })],
  head() {
    return this.$buildHead();
  },
  computed: {
    ...mapState('workspace', ['workspace']),
    ...mapState('view', ['view']),
    pageStateComponent() {
      return this.view.page_state_components?.single_item?.main_section || {};
    },
    primaryBtn() {
      return this.pageStateComponent.primary_button || {};
    },
    routesWithoutTopbar() {
      return routesWithoutTopbar.includes(this.getRouteBaseName());
    },
    isPublicView() {
      return this.$route.name?.includes(ROUTES.public.view.routeName);
    },
    isPublicRecord() {
      return this.$route.name?.includes(ROUTES.public.view.record.routeName);
    },
    isPublicViewOrRecord() {
      return this.isPublicView || this.isPublicRecord;
    },
    showApplyButton() {
      return this.isPublicViewOrRecord
        && !this.$auth.loggedIn
        && this.$vuetify.breakpoint.smAndDown
        && this.workspace.signup_enabled;
    },
  },
  mounted() {
    this.setOutsideStudio();
    this.setCurrentEnvironment('public');
    const utmsObject = this._.pickBy(this.$route.query, (_value, key) => this._.startsWith(key, 'utm_'));

    if (!this._.isEmpty(utmsObject)) {
      window.analytics.track('referredFrom', utmsObject);
    }
  },
  destroyed() {
    this.resetWorkspace();
  },
  methods: {
    ...mapMutations('environment', ['setCurrentEnvironment']),
    ...mapMutations('studio', ['setOutsideStudio']),
    ...mapMutations('workspace', ['resetWorkspace']),
    onApplyButtonClick() {
      if (this.isPublicRecord && this.primaryBtn.action === 'redirect_to_view') {
        const action = new RedirectToView(this);

        action.execute({
          ...this.primaryBtn.params,
          viewId: this.primaryBtn.params?.view_id,
          recordId: this.$route.params.recordId,
        });

        return;
      }

      this.$router.push(this.localePath({
        name: 't-tenantSlug-sign-up',
        params: {
          tenantSlug: this.$route.params.tenantSlug,
        },
      }));
    },
  },
};
</script>
<style lang="scss">
.public-layout {
  --z-the-landscape-max-width: 1280px;

  // Needs to be redeclared the same as :root in order replace the overriden var current value there
  // https://stackoverflow.com/a/63836563
  --z-the-landscape-to-viewport-distance: calc((100vw - var(--z-the-landscape-max-width)) / 2);

  --z-the-landscape-inline-spacing: max(
    var(--z-the-landscape-to-viewport-distance),
    var(--z-the-landscape-outer-spacing)
  );
}

.public-layout__apply-button {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;
  margin: 16px auto;
  width: 100%;
  max-width: fit-content;
}
</style>
