import { escapeRegExp, isUndefined } from 'lodash';
import { FORMULA_FUNCTIONS_SORTED_BY_LENGTH } from '~/assets/javascript/constants';
import { MENTION_CHARS_MAP, PARSE_STEP_FROM_REGEX } from './constants';
import { getFilterStartIndex } from './mention-items-list';

// ---------------------------------------- //
// Mention tag to readable notation parsers //
// ---------------------------------------- //

export const fieldTagToNotationParser = (mentionElement) => {
  mentionElement.outerHTML = `{{${mentionElement.dataset.id}, level:0}}`;
};

export const childFieldTagToNotationParser = (mentionElement) => {
  mentionElement.outerHTML = `{{${mentionElement.dataset.id}}}`;
};

export const chainTagToNotationParser = (mentionElement) => {
  mentionElement.outerHTML = `.{{${mentionElement.dataset.id}}}`;
};

export const stepTagToNotationParser = fieldTagToNotationParser;

export const formulaFunctionTagToNotationParser = (mentionElement) => { mentionElement.outerHTML = mentionElement.dataset.value; };

export const customTagToNotationParser = (mentionElement) => {
  mentionElement.outerHTML = `{{${mentionElement.dataset.id}}}`;
};

// ---------------------------------------- //
// Readable notation to mention tag parsers //
// ---------------------------------------- //

export const defaultTag = (option, index, char, level) => {
  const data = {
    index,
    'denotation-char': char,
    id: option.id,
    value: option.value,
    level,
  };

  const dataAttributes = Object.entries(data).map(
    ([key, value]) => (isUndefined(value) ? '' : `data-${key}="${value}"`),
  ).filter(v => v !== '').join(' ');

  return `<span class="mention" ${dataAttributes}>${char}<span contenteditable="false"><span class="ql-mention-denotation-char">${
    char
  }</span>${
    option.value
  }</span></span>`;
};

const formulaTag = option => `<span class="mention" data-id="${option.id}" data-value="${option.value}" data-denotation-char="${MENTION_CHARS_MAP.formula_function}">${option.value}</span>`;

const notationToTagParser = (html, options, mention) => {
  let parsed = html;

  options.forEach((option, index) => {
    // `Some text {{${lazy.$firstFieldId}, level:0}}.{{${lazy.$secondFieldId}} more text`
    const regex = new RegExp(`({{(step|field)_id:[A-Za-z0-9-]*(, level:0)?}}\\s?)?((\\.\\s?)?{{${escapeRegExp(option.id)}(, level:0)?}})`, 'g');
    const matches = [...parsed.matchAll(regex)];

    matches.forEach((match) => {
      let char = MENTION_CHARS_MAP[mention];
      let level;

      // match[5] is the (\\.\\s?)? --> it will exist when the match is a chain reference.
      // chain references don't have levels
      if (match[5]) {
        char = MENTION_CHARS_MAP.chain;
      } else if (match[6]) {
        // in this case, the match is the first field reference in a chain
        // match[6] is the (, level:0)? --> it will exist when the match has a level defined
        level = 0;
      } else {
        // in this case, the match is the first field reference in a chain
        // before we define the level, we need to check if the match is inside a filter
        // if it is, we don't need to define the level, because the level is already defined by the filter context
        const expressionBeforeMatch = match.input.slice(0, match.index);
        const filterStartIndex = getFilterStartIndex(expressionBeforeMatch, char);

        if (filterStartIndex === -1) {
          // if the match is not inside a filter, we define the level as 0
          level = 0;
        } else {
          // if the match is inside a filter, we don't need to define the level
          // but the char is a child_field char
          char = MENTION_CHARS_MAP.child_field;
        }
      }

      // match[4] is the ((\\.\\s?)?{{${option.id}}}), which is like {{field_id:123}} for non-chains and .{{field_id:123}} for chains
      parsed = parsed.replace(match[4], defaultTag(option, index, char, level));
    });
  });

  return parsed;
};

export const fieldNotationToTagParser = (html, options) => notationToTagParser(html, options, 'field');

export const chainNotationToTagParser = (html, _options) => html;

export const childFieldNotationToTagParser = (html, _options) => html;

export const stepNotationToTagParser = (html, options) => notationToTagParser(html, options, 'step');

export const customNotationToTagParser = (html, options) => notationToTagParser(html, options, 'custom');

export const formulaFunctionNotationToTagParser = (html, _options) => {
  let parsed = html;

  FORMULA_FUNCTIONS_SORTED_BY_LENGTH.forEach((option) => {
    const regex = new RegExp(`(${escapeRegExp(option.value)})(\\s*?\\()`, 'g');
    parsed = parsed.replaceAll(regex, `${formulaTag(option)}$2`);
  });

  return parsed;
};

export const parseStepsFromFormulaNotation = (html) => {
  let parsed = html;

  const matches = [...html.matchAll(PARSE_STEP_FROM_REGEX)];

  matches.forEach((match) => {
    const stepId = match[1];
    const fieldId = match[2];

    if (match[3]) {
      parsed = parsed.replace(match[0], `{{step_id:${stepId}, level:0}}.{{field_id:${fieldId}, level:0}}`);
    } else {
      parsed = parsed.replace(match[0], `{{step_id:${stepId}}}.{{field_id:${fieldId}}}`);
    }
  });

  return parsed;
};
