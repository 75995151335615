import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6bb2a623 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _00b57c3d = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _09634d48 = () => interopDefault(import('../pages/magic-link/index.vue' /* webpackChunkName: "pages/magic-link/index" */))
const _104ee85b = () => interopDefault(import('../pages/reset-password/index.vue' /* webpackChunkName: "pages/reset-password/index" */))

const _1bf6e13b = () => interopDefault(import('../pages/set-workspace.vue' /* webpackChunkName: "pages/set-workspace" */))
const _456dacbf = () => interopDefault(import('../pages/sign-up/index.vue' /* webpackChunkName: "pages/sign-up/index" */))
const _5c570fb5 = () => interopDefault(import('../pages/store/index.vue' /* webpackChunkName: "pages/store/index" */))
const _045a5ded = () => interopDefault(import('../pages/store/apps/_appName.vue' /* webpackChunkName: "pages/store/apps/_appName" */))
const _503dd6cd = () => interopDefault(import('../pages/t/_tenantSlug/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/index" */))
const _225b403c = () => interopDefault(import('../pages/t/_tenantSlug/email-validation/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/email-validation/index" */))
const _5d000567 = () => interopDefault(import('../pages/t/_tenantSlug/login/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/login/index" */))
const _35be2aeb = () => interopDefault(import('../pages/t/_tenantSlug/m/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/m/index" */))
const _172779a4 = () => interopDefault(import('../pages/t/_tenantSlug/p/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/p/index" */))
const _b652db24 = () => interopDefault(import('../pages/t/_tenantSlug/reset-password.vue' /* webpackChunkName: "pages/t/_tenantSlug/reset-password" */))
const _8d8ad92e = () => interopDefault(import('../pages/t/_tenantSlug/sign-up/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/sign-up/index" */))
const _72fee3ae = () => interopDefault(import('../pages/t/_tenantSlug/studio/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/index" */))
const _02d19b6f = () => interopDefault(import('../pages/t/_tenantSlug/m/account-configuration.vue' /* webpackChunkName: "pages/t/_tenantSlug/m/account-configuration" */))
const _f0e35b76 = () => interopDefault(import('../pages/t/_tenantSlug/sso/saml/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/sso/saml/index" */))
const _acff98fe = () => interopDefault(import('../pages/t/_tenantSlug/studio/data/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/data/index" */))
const _3ad22d77 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings" */))
const _788e7eba = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/index" */))
const _1c22089a = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/app-builder/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/app-builder/index" */))
const _55a28d43 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/authentication/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/authentication/index" */))
const _3615839f = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/global-parameters-manager/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/global-parameters-manager/index" */))
const _6dedb72a = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/integrations/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/integrations/index" */))
const _78cab970 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/internal/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/internal/index" */))
const _51c4736c = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/system-entities/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/system-entities/index" */))
const _1697dde5 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/tokens/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/tokens/index" */))
const _795fb601 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/user-groups/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/user-groups/index" */))
const _33fa6b73 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/users/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/users/index" */))
const _1529cce2 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/user-groups/new.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/user-groups/new" */))
const _a61907dc = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/integrations/_id.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/integrations/_id" */))
const _3c9576ae = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/user-groups/_id.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/user-groups/_id" */))
const _1735f679 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/user-groups/_user-group-form/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/user-groups/_user-group-form/index" */))
const _77a547ba = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/user-groups/_user-group-list-item/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/user-groups/_user-group-list-item/index" */))
const _1b5fbe9e = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/users/_active-members-card/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/users/_active-members-card/index" */))
const _633a7160 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/users/_users-data-table-card/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/users/_users-data-table-card/index" */))
const _5ccafa7a = () => interopDefault(import('../pages/t/_tenantSlug/studio/automations/_id.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/automations/_id" */))
const _0146a9f4 = () => interopDefault(import('../pages/t/_tenantSlug/studio/automations/_id/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/automations/_id/index" */))
const _c28c0270 = () => interopDefault(import('../pages/t/_tenantSlug/studio/automations/_id/history.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/automations/_id/history" */))
const _3621a5ec = () => interopDefault(import('../pages/t/_tenantSlug/studio/data/_id/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/data/_id/index" */))
const _4bdd416a = () => interopDefault(import('../pages/t/_tenantSlug/studio/pages/_id.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/pages/_id" */))
const _3a508f0a = () => interopDefault(import('../pages/t/_tenantSlug/studio/data/_id/import.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/data/_id/import" */))
const _fc4eb0ca = () => interopDefault(import('../pages/t/_tenantSlug/m/_viewId/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/m/_viewId/index" */))
const _2a506644 = () => interopDefault(import('../pages/t/_tenantSlug/p/_viewId/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/p/_viewId/index" */))
const _38c6bccc = () => interopDefault(import('../pages/t/_tenantSlug/m/_viewId/r/new.vue' /* webpackChunkName: "pages/t/_tenantSlug/m/_viewId/r/new" */))
const _79ce969a = () => interopDefault(import('../pages/t/_tenantSlug/m/_viewId/r/_recordId/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/m/_viewId/r/_recordId/index" */))
const _8eee2146 = () => interopDefault(import('../pages/t/_tenantSlug/p/_viewId/r/_recordId/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/p/_viewId/r/_recordId/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _6bb2a623,
    name: "index___en"
  }, {
    path: "/login",
    component: _00b57c3d,
    name: "login___pt"
  }, {
    path: "/magic-link",
    component: _09634d48,
    name: "magic-link___pt"
  }, {
    path: "/reset-password",
    component: _104ee85b,
    name: "reset-password___pt"
  }, {
    path: "/reset-password",
    redirect: {"name":"set-tenant___pt"}
  }, {
    path: "/set-workspace",
    component: _1bf6e13b,
    name: "set-workspace___pt"
  }, {
    path: "/sign-up",
    component: _456dacbf,
    name: "sign-up___pt"
  }, {
    path: "/store",
    component: _5c570fb5,
    name: "store___pt"
  }, {
    path: "/builder/views",
    redirect: {"name":"studio-pages-id___pt"}
  }, {
    path: "/en/login",
    component: _00b57c3d,
    name: "login___en"
  }, {
    path: "/en/magic-link",
    component: _09634d48,
    name: "magic-link___en"
  }, {
    path: "/en/reset-password",
    component: _104ee85b,
    name: "reset-password___en"
  }, {
    path: "/en/reset-password",
    redirect: {"name":"set-tenant___en"}
  }, {
    path: "/en/set-workspace",
    component: _1bf6e13b,
    name: "set-workspace___en"
  }, {
    path: "/en/sign-up",
    component: _456dacbf,
    name: "sign-up___en"
  }, {
    path: "/en/store",
    component: _5c570fb5,
    name: "store___en"
  }, {
    path: "/en/builder/views",
    redirect: {"name":"studio-pages-id___en"}
  }, {
    path: "/studio/settings/integrations",
    redirect: {"name":"t-tenantSlug-studio-settings-integrations___pt"}
  }, {
    path: "/en/studio/settings/integrations",
    redirect: {"name":"t-tenantSlug-studio-settings-integrations___en"}
  }, {
    path: "/",
    component: _6bb2a623,
    name: "index___pt"
  }, {
    path: "/en/builder/workflows/:id?",
    redirect: {"name":"studio-automations-id___en"}
  }, {
    path: "/en/store/apps/:appName?",
    component: _045a5ded,
    name: "store-apps-appName___en"
  }, {
    path: "/builder/workflows/:id?",
    redirect: {"name":"studio-automations-id___pt"}
  }, {
    path: "/en/t/:tenantSlug",
    component: _503dd6cd,
    name: "t-tenantSlug___en"
  }, {
    path: "/store/apps/:appName?",
    component: _045a5ded,
    name: "store-apps-appName___pt"
  }, {
    path: "/en/t/:tenantSlug?/email-validation",
    component: _225b403c,
    name: "t-tenantSlug-email-validation___en"
  }, {
    path: "/en/t/:tenantSlug?/login",
    component: _5d000567,
    name: "t-tenantSlug-login___en"
  }, {
    path: "/en/t/:tenantSlug?/m",
    component: _35be2aeb,
    name: "t-tenantSlug-m___en"
  }, {
    path: "/en/t/:tenantSlug?/p",
    component: _172779a4,
    name: "t-tenantSlug-p___en"
  }, {
    path: "/en/t/:tenantSlug?/reset-password",
    component: _b652db24,
    name: "t-tenantSlug-reset-password___en"
  }, {
    path: "/en/t/:tenantSlug?/sign-up",
    component: _8d8ad92e,
    name: "t-tenantSlug-sign-up___en"
  }, {
    path: "/en/t/:tenantSlug?/studio",
    component: _72fee3ae,
    name: "t-tenantSlug-studio___en"
  }, {
    path: "/en/t/:tenantSlug?/m/account-configuration",
    component: _02d19b6f,
    name: "t-tenantSlug-m-account-configuration___en"
  }, {
    path: "/en/t/:tenantSlug?/sso/saml",
    component: _f0e35b76,
    name: "t-tenantSlug-sso-saml___en"
  }, {
    path: "/en/t/:tenantSlug?/studio/data",
    component: _acff98fe,
    name: "t-tenantSlug-studio-data___en"
  }, {
    path: "/en/t/:tenantSlug?/studio/settings",
    component: _3ad22d77,
    children: [{
      path: "",
      component: _788e7eba,
      name: "t-tenantSlug-studio-settings___en"
    }, {
      path: "app-builder",
      component: _1c22089a,
      name: "t-tenantSlug-studio-settings-app-builder___en"
    }, {
      path: "authentication",
      component: _55a28d43,
      name: "t-tenantSlug-studio-settings-authentication___en"
    }, {
      path: "global-parameters-manager",
      component: _3615839f,
      name: "t-tenantSlug-studio-settings-global-parameters-manager___en"
    }, {
      path: "integrations",
      component: _6dedb72a,
      name: "t-tenantSlug-studio-settings-integrations___en"
    }, {
      path: "internal",
      component: _78cab970,
      name: "t-tenantSlug-studio-settings-internal___en"
    }, {
      path: "system-entities",
      component: _51c4736c,
      name: "t-tenantSlug-studio-settings-system-entities___en"
    }, {
      path: "tokens",
      component: _1697dde5,
      name: "t-tenantSlug-studio-settings-tokens___en"
    }, {
      path: "user-groups",
      component: _795fb601,
      name: "t-tenantSlug-studio-settings-user-groups___en"
    }, {
      path: "users",
      component: _33fa6b73,
      name: "t-tenantSlug-studio-settings-users___en"
    }, {
      path: "user-groups/new",
      component: _1529cce2,
      name: "t-tenantSlug-studio-settings-user-groups-new___en"
    }, {
      path: "integrations/:id",
      component: _a61907dc,
      name: "t-tenantSlug-studio-settings-integrations-id___en"
    }, {
      path: "user-groups/:id?",
      component: _3c9576ae,
      name: "t-tenantSlug-studio-settings-user-groups-id___en"
    }, {
      path: "user-groups/:user-group-form",
      component: _1735f679,
      name: "t-tenantSlug-studio-settings-user-groups-user-group-form___en"
    }, {
      path: "user-groups/:user-group-list-item",
      component: _77a547ba,
      name: "t-tenantSlug-studio-settings-user-groups-user-group-list-item___en"
    }, {
      path: "users/:active-members-card",
      component: _1b5fbe9e,
      name: "t-tenantSlug-studio-settings-users-active-members-card___en"
    }, {
      path: "users/:users-data-table-card",
      component: _633a7160,
      name: "t-tenantSlug-studio-settings-users-users-data-table-card___en"
    }]
  }, {
    path: "/en/t/:tenantSlug?/studio/automations/:id?",
    component: _5ccafa7a,
    children: [{
      path: "",
      component: _0146a9f4,
      name: "t-tenantSlug-studio-automations-id___en"
    }, {
      path: "history",
      component: _c28c0270,
      name: "t-tenantSlug-studio-automations-id-history___en"
    }]
  }, {
    path: "/en/t/:tenantSlug?/studio/data/:id",
    component: _3621a5ec,
    name: "t-tenantSlug-studio-data-id___en"
  }, {
    path: "/en/t/:tenantSlug?/studio/pages/:id?",
    component: _4bdd416a,
    name: "t-tenantSlug-studio-pages-id___en"
  }, {
    path: "/en/t/:tenantSlug?/studio/data/:id/import",
    component: _3a508f0a,
    name: "t-tenantSlug-studio-data-id-import___en"
  }, {
    path: "/en/t/:tenantSlug?/m/:viewId",
    component: _fc4eb0ca,
    name: "t-tenantSlug-m-viewId___en"
  }, {
    path: "/en/t/:tenantSlug?/p/:viewId",
    component: _2a506644,
    name: "t-tenantSlug-p-viewId___en"
  }, {
    path: "/en/t/:tenantSlug?/m/:viewId/r/new",
    component: _38c6bccc,
    name: "t-tenantSlug-m-viewId-r-new___en"
  }, {
    path: "/en/t/:tenantSlug?/m/:viewId/r/:recordId",
    component: _79ce969a,
    name: "t-tenantSlug-m-viewId-r-recordId___en"
  }, {
    path: "/en/t/:tenantSlug?/p/:viewId/r/:recordId",
    component: _8eee2146,
    name: "t-tenantSlug-p-viewId-r-recordId___en"
  }, {
    path: "/t/:tenantSlug",
    component: _503dd6cd,
    name: "t-tenantSlug___pt"
  }, {
    path: "/t/:tenantSlug?/email-validation",
    component: _225b403c,
    name: "t-tenantSlug-email-validation___pt"
  }, {
    path: "/t/:tenantSlug?/login",
    component: _5d000567,
    name: "t-tenantSlug-login___pt"
  }, {
    path: "/t/:tenantSlug?/m",
    component: _35be2aeb,
    name: "t-tenantSlug-m___pt"
  }, {
    path: "/t/:tenantSlug?/p",
    component: _172779a4,
    name: "t-tenantSlug-p___pt"
  }, {
    path: "/t/:tenantSlug?/reset-password",
    component: _b652db24,
    name: "t-tenantSlug-reset-password___pt"
  }, {
    path: "/t/:tenantSlug?/sign-up",
    component: _8d8ad92e,
    name: "t-tenantSlug-sign-up___pt"
  }, {
    path: "/t/:tenantSlug?/studio",
    component: _72fee3ae,
    name: "t-tenantSlug-studio___pt"
  }, {
    path: "/t/:tenantSlug?/m/account-configuration",
    component: _02d19b6f,
    name: "t-tenantSlug-m-account-configuration___pt"
  }, {
    path: "/t/:tenantSlug?/sso/saml",
    component: _f0e35b76,
    name: "t-tenantSlug-sso-saml___pt"
  }, {
    path: "/t/:tenantSlug?/studio/data",
    component: _acff98fe,
    name: "t-tenantSlug-studio-data___pt"
  }, {
    path: "/t/:tenantSlug?/studio/settings",
    component: _3ad22d77,
    children: [{
      path: "",
      component: _788e7eba,
      name: "t-tenantSlug-studio-settings___pt"
    }, {
      path: "app-builder",
      component: _1c22089a,
      name: "t-tenantSlug-studio-settings-app-builder___pt"
    }, {
      path: "authentication",
      component: _55a28d43,
      name: "t-tenantSlug-studio-settings-authentication___pt"
    }, {
      path: "global-parameters-manager",
      component: _3615839f,
      name: "t-tenantSlug-studio-settings-global-parameters-manager___pt"
    }, {
      path: "integrations",
      component: _6dedb72a,
      name: "t-tenantSlug-studio-settings-integrations___pt"
    }, {
      path: "internal",
      component: _78cab970,
      name: "t-tenantSlug-studio-settings-internal___pt"
    }, {
      path: "system-entities",
      component: _51c4736c,
      name: "t-tenantSlug-studio-settings-system-entities___pt"
    }, {
      path: "tokens",
      component: _1697dde5,
      name: "t-tenantSlug-studio-settings-tokens___pt"
    }, {
      path: "user-groups",
      component: _795fb601,
      name: "t-tenantSlug-studio-settings-user-groups___pt"
    }, {
      path: "users",
      component: _33fa6b73,
      name: "t-tenantSlug-studio-settings-users___pt"
    }, {
      path: "user-groups/new",
      component: _1529cce2,
      name: "t-tenantSlug-studio-settings-user-groups-new___pt"
    }, {
      path: "integrations/:id",
      component: _a61907dc,
      name: "t-tenantSlug-studio-settings-integrations-id___pt"
    }, {
      path: "user-groups/:id?",
      component: _3c9576ae,
      name: "t-tenantSlug-studio-settings-user-groups-id___pt"
    }, {
      path: "user-groups/:user-group-form",
      component: _1735f679,
      name: "t-tenantSlug-studio-settings-user-groups-user-group-form___pt"
    }, {
      path: "user-groups/:user-group-list-item",
      component: _77a547ba,
      name: "t-tenantSlug-studio-settings-user-groups-user-group-list-item___pt"
    }, {
      path: "users/:active-members-card",
      component: _1b5fbe9e,
      name: "t-tenantSlug-studio-settings-users-active-members-card___pt"
    }, {
      path: "users/:users-data-table-card",
      component: _633a7160,
      name: "t-tenantSlug-studio-settings-users-users-data-table-card___pt"
    }]
  }, {
    path: "/t/:tenantSlug?/studio/automations/:id?",
    component: _5ccafa7a,
    children: [{
      path: "",
      component: _0146a9f4,
      name: "t-tenantSlug-studio-automations-id___pt"
    }, {
      path: "history",
      component: _c28c0270,
      name: "t-tenantSlug-studio-automations-id-history___pt"
    }]
  }, {
    path: "/t/:tenantSlug?/studio/data/:id",
    component: _3621a5ec,
    name: "t-tenantSlug-studio-data-id___pt"
  }, {
    path: "/t/:tenantSlug?/studio/pages/:id?",
    component: _4bdd416a,
    name: "t-tenantSlug-studio-pages-id___pt"
  }, {
    path: "/t/:tenantSlug?/studio/data/:id/import",
    component: _3a508f0a,
    name: "t-tenantSlug-studio-data-id-import___pt"
  }, {
    path: "/t/:tenantSlug?/m/:viewId",
    component: _fc4eb0ca,
    name: "t-tenantSlug-m-viewId___pt"
  }, {
    path: "/t/:tenantSlug?/p/:viewId",
    component: _2a506644,
    name: "t-tenantSlug-p-viewId___pt"
  }, {
    path: "/t/:tenantSlug?/m/:viewId/r/new",
    component: _38c6bccc,
    name: "t-tenantSlug-m-viewId-r-new___pt"
  }, {
    path: "/t/:tenantSlug?/m/:viewId/r/:recordId",
    component: _79ce969a,
    name: "t-tenantSlug-m-viewId-r-recordId___pt"
  }, {
    path: "/t/:tenantSlug?/p/:viewId/r/:recordId",
    component: _8eee2146,
    name: "t-tenantSlug-p-viewId-r-recordId___pt"
  }, {
    path: "/:locale(en)?/builder/:after(.*)",
    redirect: (to) => ({ path: `${to.params.locale}/studio/${to.params.after}` })
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
