export const addProtocolToMarkdownLinks = (markdown) => {
  if (!markdown) return markdown;

  // the pattern cannot start with ! or !! because it is used for image and video content
  // we match it with initial of the text or that not is started by "!" so we can get all the possible cases
  // note that we need to put it back in order to do not alter the markdown
  // and the Negative Lookbehind feature cannot be used
  // becase it's not supported by all browsers yet :feelsbadman:
  return markdown.replaceAll(/((?:[^!]|^)\[.+?])\((.+?)\)/gmi, (_match, p1, p2) => {
    const url = p2.startsWith('http') || !p2.match(/^[a-zA-Z-]+?\.[a-zA-Z-]+?/)
      ? p2
      : `https://${p2}`;
    return `${p1}(${url})`;
  });
};
