import { calculateFieldNameWithGroup } from '../../fields';
import {
  chainNotationToTagParser,
  chainTagToNotationParser,
  childFieldNotationToTagParser,
  childFieldTagToNotationParser,
  customNotationToTagParser,
  customTagToNotationParser,
  fieldNotationToTagParser,
  fieldTagToNotationParser,
  formulaFunctionNotationToTagParser,
  formulaFunctionTagToNotationParser,
  stepNotationToTagParser,
  stepTagToNotationParser,
} from './parsers';

import {
  getFieldItemsList,
  getChainItemsList,
  getStepItemsList,
  getFormulaFunctionItemsList,
  getCustomItemsList,
  getChildFieldItemsList,
} from './mention-items-list';

import { UNDEFINED_STRATEGY, MENTION_CHARS_MAP } from './constants';

// ---------------------------------------- //
// Strategies map                           //
// ---------------------------------------- //
/**
 * MENTION_STRATEGIES is a map that contains the strategies for each mention type.
 * Each strategy has two parsers: tagToNotation and notationToTag.
 * tagToNotation is used to parse the html mention tag to formula notation.
 * notationToTag is used to parse the formula notation to html mention tag.
 * Each strategy also has an mentionListOptionsFormatter function that formats the options to be used in the mention list.
 * Each strategy also has a getItemsList function that gets the items list for the mention list.
 */
const MENTION_STRATEGIES = {
  field: {
    parsers: {
      tagToNotation: fieldTagToNotationParser,
      notationToTag: fieldNotationToTagParser,
    },
    mentionListOptionsFormatter: (options, level) => options.map(field => ({
      id: `field_id:${field.id}`,
      value: calculateFieldNameWithGroup(field),
      level,
    })),
    getItemsList: getFieldItemsList,
  },
  child_field: {
    parsers: {
      tagToNotation: childFieldTagToNotationParser,
      notationToTag: childFieldNotationToTagParser,
    },
    mentionListOptionsFormatter: (options, _level) => options.map(field => ({
      id: `field_id:${field.id}`,
      value: calculateFieldNameWithGroup(field),
    })),
    getItemsList: getChildFieldItemsList,
  },
  chain: {
    parsers: {
      tagToNotation: chainTagToNotationParser,
      notationToTag: chainNotationToTagParser,
    },
    getItemsList: getChainItemsList,
  },
  step: {
    parsers: {
      tagToNotation: stepTagToNotationParser,
      notationToTag: stepNotationToTagParser,
    },
    mentionListOptionsFormatter: (options, level) => options.map(({ id, value, name }) => ({
      id: `${name ? 'field' : 'step'}_id:${id}`,
      value: value || name,
      level,
    })),
    getItemsList: getStepItemsList,
  },
  formula_function: {
    parsers: {
      tagToNotation: formulaFunctionTagToNotationParser,
      notationToTag: formulaFunctionNotationToTagParser,
    },
    mentionListOptionsFormatter: (options, _level) => options,
    getItemsList: getFormulaFunctionItemsList,
  },
  custom: {
    parsers: {
      tagToNotation: customTagToNotationParser,
      notationToTag: customNotationToTagParser,
    },
    mentionListOptionsFormatter: (options, _level) => options,
    getItemsList: getCustomItemsList,
  },
};

export const getStrategyByMentionChar = (char, availableStrategies = []) => {
  const ignoreFieldStrategy = availableStrategies.includes('custom');
  const mentionStrategy = Object.entries(MENTION_CHARS_MAP).find(
    ([strategyKey, mentionChar]) => mentionChar === char
    && ((ignoreFieldStrategy && strategyKey !== 'field') || !ignoreFieldStrategy),
  )?.[0];
  if (!mentionStrategy) return UNDEFINED_STRATEGY;

  return MENTION_STRATEGIES[mentionStrategy] || UNDEFINED_STRATEGY;
};

export const getMentionStrategy = strategy => MENTION_STRATEGIES[strategy] || UNDEFINED_STRATEGY;

export { UNDEFINED_STRATEGY };
